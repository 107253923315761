.loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #343E42; /* Color de fondo */
    z-index: 10000; /* Asegúrate de que esté por encima de otros elementos */
}

.heart {
    width: 20vw; /* Ajusta el tamaño de la imagen en relación al ancho de la pantalla */
    max-width: 200px; /* Tamaño máximo para pantallas grandes */
    height: auto; /* Mantiene la relación de aspecto */
    animation: pulse 2s infinite; /* Pulso */
}

@keyframes pulse {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2); /* Aumenta el tamaño al 120% */
    }
}
