.wpo-section-title,
.wpo-section-title-s2 {
	margin-bottom: 180px;
	text-align: center;

	@include media-query(767px) {
		margin-bottom: 100px;
		margin-top: 30px;
	}

	span {
		display: block;
		font-size: 25px;
		font-style: normal;
		font-weight: 400;
		text-align: center;
		color: white;

		@include media-query(767px) {
			font-size: 25px;
			line-height: 35px;
			margin-bottom: 0;

		}

		@include media-query(575px) {
			font-size: 18px;
			margin-bottom: 0;

		}
	}

	h2 {
		font-family: Sail;
		font-size: 50px;
		font-weight: 400;
		margin: 0;
		position: relative;
		color: white;
		display: inline-block;
		position: relative;
		margin-top: 10px;

		@include media-query(767px) {
			font-size: 32px;
			line-height: 40px;
		}

		@include media-query(575px) {
			font-size: 25px;
			margin-top: 0;
		}

	}

	p {
		font-size: 18px;
	}
}


.wpo-section-title-s2 {
	text-align: left;

	@include media-query(991px) {
		text-align: center;
	}

	span{
		text-align: left;

		@include media-query(991px) {
			text-align: center;
		}
	}
}

.wpo-section-title-white,
.wpo-section-title-s2 {
	margin-bottom: 180px;
	text-align: center;

	@include media-query(767px) {
		margin-bottom: 30px;
	}

	span {
		display: block;
		font-size: 25px;
		font-style: normal;
		font-weight: 400;
		text-align: center;
		color: white;

		@include media-query(767px) {
			font-size: 25px;
			line-height: 35px;
			margin-bottom: 15px;

		}

		@include media-query(575px) {
			font-size: 18px;
			margin-bottom: 15px;

		}
	}

	h2 {
		font-family: Sail;
		font-size: 50px;
		font-weight: 400;
		margin: 0;
		position: relative;
		color: white;
		display: inline-block;
		position: relative;
		margin-top: 10px;

		@include media-query(767px) {
			font-size: 32px;
			line-height: 40px;
		}

		@include media-query(575px) {
			font-size: 25px;
			margin-top: 0;
		}

	}

	p {
		font-size: 18px;
	}
}


.wpo-section-title-s2 {
	text-align: left;

	@include media-query(991px) {
		text-align: center;
	}

	span{
		text-align: left;

		@include media-query(991px) {
			text-align: center;
		}
	}
}

.wpo-section-title-grey,
.wpo-section-title-s2 {
	margin-bottom: 180px;
	text-align: center;

	@include media-query(767px) {
		margin-bottom: 120px;
	}

	span {
		display: block;
		font-size: 25px;
		font-style: normal;
		font-weight: 400;
		text-align: center;
		color: #343E42;

		@include media-query(767px) {
			font-size: 25px;
			line-height: 35px;
			margin-bottom: 15px;

		}

		@include media-query(575px) {
			font-size: 18px;
			margin-bottom: 15px;

		}
	}

	h2 {
		font-family: Sail;
		font-size: 50px;
		font-weight: 400;
		margin: 0;
		position: relative;
		color: #343E42;
		display: inline-block;
		position: relative;
		margin-top: 10px;

		@include media-query(767px) {
			font-size: 32px;
			line-height: 40px;
		}

		@include media-query(575px) {
			font-size: 25px;
			margin-top: 0;
		}

	}

	p {
		font-size: 18px;
	}
}


.wpo-section-title-s2 {
	text-align: left;

	@include media-query(991px) {
		text-align: center;
	}

	span{
		text-align: left;

		@include media-query(991px) {
			text-align: center;
		}
	}
}